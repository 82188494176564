import { env } from "../lib/utils";

const config = {

    secret: env('SECRET', ''),

    magento2: {
        url: env('MAGENTO2_URL')
    },

    adyen: {
        environment: env('ADYEN_ENV', 'test'),
        clientKey: env('ADYEN_CLIENT_KEY', 'test_5LCTKG6NLVHVLPDDBB33JDERVAAPI4BO'),
        paymentMethods: {
            ideal: "adyen_hpp",
            scheme: "adyen_cc"
        },
        showPayButton: true,
        paymentMethodsConfiguration: {
            paypal: {
                showPayButton: true
            }
        },
        brands: {
            amex: "AE",
            visa: "VI",
            mc: "MC",
            discover: "DC",
            maestro: "SM",
            jcb: "JCB",
            diners: "DN",
            cup: "UN",
            bcmc: "BCMC",
            hipercard: "HIPERCARD",
            elo: "ELO",
            troy: "TROY",
            dankort: "DANKORT",
            cartebancaire: "CB",
            korean_local_card: "KCP",
            amex_applepay: "AMEXAPPLEPAY",
            discover_applepay: "DISCOVERAPPLEPAY",
            electron_applepay: "ELECTRONAPPLEPAY",
            elo_applepay: "ELOAPPLEPAY",
            elodebit_applepay: "ELODEBITAPPLEPAY",
            interac_applepay: "INTERACAPPLEPAY",
            jcb_applepay: "JCBAPPLEPAY",
            maestro_applepay: "MAESTROAPPLEPAY",
            mc_applepay: "MCAPPLEPAY",
            visa_applepay: "VIAPPLEPAY",
            girocard_applepay: "GIROCARDAPPLEPAY",
        }
    },

    google: {
        placesApiKey: 'AIzaSyDwEaj7zocUIOUTx1qyROpXQcpNViUzG4Q',
        recaptchakey: env('RECAPTCHA_KEY','6LfPeFgpAAAAAJMmWWKh7-cQ4_-1ElcT_jwvdVlh'),
        recaptchasecretkey: env('RECAPTCHA_SECRET_KEY','6LfPeFgpAAAAALEfZJ2YIVSyA7QCcMsjvzGB6QJm')
    },

    algolia: {
        index: env('ALGOLIA_INDEX_PREFIX', 'magento2_ea_test_'),
        appId: env('ALGOLIA_APP_ID', 'testing8IHM0U9VNE'),
        apiKey: env('ALGOLIA_API_KEY', 'a1ca0609d546723e64edadf0f68a1e35')
    },

    form: {
        validation: {
            password: {
                minLength: 8,
                maxLength: 256,
                requiredCharClasses: 3
            },
            zipcode: {
                error: 'account.address.form.validation.zipcode.default',
                BE: {
                    pattern: '^\\d{4}$',
                },
                NL: {
                    pattern: '^[1-9]\\d{3} [A-Z]{2}|[1-9]\\d{3}[A-Z]{2}$',
                },
                FR: {
                    pattern: '^\\d{5}$',
                },
                DE: {
                    pattern: '^\\d{5}$',
                },
                GB: {
                    pattern: '^([G][I][R] 0[A]{2})|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})$',
                },
                US: {
                    pattern: '^\\d{5}(-\\d{4})?$',
                },
                AT: {
                    pattern: '^\\d{4}$',
                },
                BG: {
                    pattern: '^\\d{4}$',
                },
                HR: {
                    pattern: '^[1-5]\\d{4}$',
                },
                CY: {
                    pattern: '^[1-9]\\d{3}$',
                },
                CZ: {
                    pattern: '^[1-7][0-9]{2} [0-9]{2}|[1-7][0-9]{4}$',
                },
                DK: {
                    pattern: '^\\d{4}$',
                },
                EE: {
                    pattern: '^\\d{5}$',
                },
                FI: {
                    pattern: '^\\d{5}$',
                },
                GR: {
                    pattern: '^(\\d{3}) \\d{2}|\\d{5}$',
                },
                HU: {
                    pattern: '^[1-9]\\d{3}$',
                },
                IE: {
                    pattern: '^(?:[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$',
                },
                IT: {
                    pattern: '^\\d{5}$',
                },
                LV: {
                    pattern: '^((LV)[\\-])?(\\d{4})$',
                },
                LT: {
                    pattern: '^((LT)[\\-])?(\\d{5})$',
                },
                LU: {
                    pattern: '^((L)[\\-])?(\\d{4})$',
                },
                MT: {
                    pattern: '^[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}$',
                },
                PL: {
                    pattern: '^[0-9]{2}[-]([0-9]){3}$',
                },
                PT: {
                    pattern: '^[1-9]\\d{3}((-)\\d{3})$',
                },
                RO: {
                    pattern: '^\\d{6}$',
                },
                SK: {
                    pattern: '^(\\d{3} \\d{2})|\\d{5}$',
                },
                SI: {
                    pattern: '^[1-9]\\d{3}$',
                },
                SE: {
                    pattern: '^[1-9]\\d{2} \\d{2}$',
                },
                ES: {
                    pattern: '^\\d{5}$',
                }
            },
            telephone: {
                default: {
                    pattern: '^00[0-9]+$',
                    error: 'account.address.form.validation.telephone.default'
                },
                NL: {
                    pattern: '^0031[0-9]+$',
                    error: 'account.address.form.validation.telephone.NL'
                }
            },
            address: {
                // default: {
                //     pattern: '^[a-zA-Z\\.\\- \'à-üÀ-ÜàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœč]+ [0-9-]+[a-zA-z]{0,3}$',
                //     error: 'account.address.form.validation.address.default'
                // },
                US: {
                    pattern: '^[0-9-]+[a-zA-z]{0,3} [a-zA-Z\\.\\- \'à-üÀ-ÜàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœč0-9]+$',
                    error: 'account.address.form.validation.address.US'
                }
            }
        }
    },

    productlist: {
        pagination: {
            defaultPerPage: 30,
            perPage: [30, 50, 100]
        },
        sort: [
            { key: '' },
            { key: '_abc' },
            { key: '_price_asc' },
            { key: '_price_desc' }
        ]
    },

    defaultStoreCode: 'be_nl',

    billingCountries: [
        'BE','NL','FR','DE','GB','IE','US','ES','IT', 'AT','BG','HR','CY','CZ','DK','EE','FI','GR','HU','PL','LV','LT','LU','MT','PT','RO','SK','SI','SE'
    ],

    storeViews: {

        be_nl: {
            name: 'Belgie (nl)',
            locale: 'nl-BE',
            checkoutLocale: 'nl_NL',
            hreflang: 'nl-BE',
            country: 'be',
            currency: 'EUR',
            language: 'nl',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },
            freeShippingFrom: 200,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'be'
            ],
            displayTaxBeforeAddress: true
        },

        be_nl_kiosk: {
            name: 'Belgie (nl) kiosk',
            locale: 'nl-BE',
            checkoutLocale: 'nl_NL',
            country: 'be',
            currency: 'EUR',
            language: 'nl',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            freeShippingFrom: 0,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'be'
            ],
            displayTaxBeforeAddress: true
        },

        be_fr: {
            name: 'Belgique (fr)',
            locale: 'fr-BE',
            checkoutLocale: 'fr_FR',
            hreflang: 'fr-BE',
            country: 'be',
            currency: 'EUR',
            language: 'fr',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'be'
            ],
            displayTaxBeforeAddress: true
        },

        be_fr_kiosk: {
            name: 'Belgique (fr) kiosk',
            locale: 'fr-BE',
            checkoutLocale: 'fr_FR',
            country: 'be',
            currency: 'EUR',
            language: 'fr',
            phone: '+3232014974',
            shippingCost: 0,
            freeShippingFrom: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'be'
            ],
            displayTaxBeforeAddress: true

        },

        be_en: {
            name: 'Belgium (en)',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'en-BE',
            country: 'be',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 200,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'be'
            ],
            displayTaxBeforeAddress: true

        },

        be_en_kiosk: {
            name: 'Belgium (en) kiosk',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            country: 'be',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 0,
            freeShippingFrom: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'be'
            ],
            displayTaxBeforeAddress: true

        },

        nl_nl: {
            name: 'Nederland (nl)',
            locale: 'nl-NL',
            checkoutLocale: 'nl_NL',
            hreflang: 'nl-NL',
            country: 'nl',
            currency: 'EUR',
            language: 'nl',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'nl'
            ],
            displayTaxBeforeAddress: true

        },

        nl_nl_kiosk: {
            name: 'Nederland (nl) kiosk',
            locale: 'nl-NL',
            checkoutLocale: 'nl_NL',
            country: 'nl',
            currency: 'EUR',
            language: 'nl',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            freeShippingFrom: 0,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'nl'
            ],
            displayTaxBeforeAddress: true

        },

        nl_en_kiosk: {
            name: 'Netherlands (en) kiosk',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'en-NL',
            country: 'nl',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            freeShippingFrom: 0,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'nl'
            ],
            displayTaxBeforeAddress: true

        },

        nl_en: {
            name: 'Netherlands (en)',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'en-NL',
            country: 'nl',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },
            freeShippingFrom: 150,
            vatPercentage: 21,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'nl'
            ],
            displayTaxBeforeAddress: true

        },

        de_de: {
            name: 'Deutschland (de)',
            locale: 'de-DE',
            checkoutLocale: 'de_DE',
            hreflang: 'de-DE',
            country: 'de',
            currency: 'EUR',
            language: 'de',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 19,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'de'
            ],
            displayTaxBeforeAddress: true

        },

        de_de_kiosk: {
            name: 'Deutschland (de) kiosk',
            locale: 'de-DE',
            checkoutLocale: 'de_DE',
            country: 'de',
            currency: 'EUR',
            language: 'de',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            freeShippingFrom: 0,
            vatPercentage: 19,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'de'
            ],
            displayTaxBeforeAddress: true

        },

        de_en: {
            name: 'Germany (en)',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'en-DE',
            country: 'de',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 19,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'de'
            ],
            displayTaxBeforeAddress: true

        },

        de_en_kiosk: {
            name: 'Germany (en) kiosk',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            country: 'de',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },

            freeShippingFrom: 0,
            vatPercentage: 19,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'de'
            ]
        },

        fr_fr: {
            name: 'France (fr)',
            locale: 'fr-FR',
            checkoutLocale: 'fr_FR',
            hreflang: 'fr-FR',
            country: 'fr',
            currency: 'EUR',
            language: 'fr',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'fr'
            ],
            displayTaxBeforeAddress: true

        },

        fr_fr_kiosk: {
            name: 'France (fr) kiosk',
            locale: 'fr-FR',
            checkoutLocale: 'fr_FR',
            country: 'fr',
            currency: 'EUR',
            language: 'fr',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },

            freeShippingFrom: 0,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'fr'
            ],
            displayTaxBeforeAddress: true

        },

        fr_en: {
            name: 'France (en)',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'en-FR',
            country: 'fr',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'fr'
            ],
            displayTaxBeforeAddress: true

        },

        fr_en_kiosk: {
            name: 'France (en) kiosk',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            country: 'fr',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },

            freeShippingFrom: 0,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'fr'
            ],
            displayTaxBeforeAddress: true

        },

        uk: {
            name: 'United Kingdom (en)',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'en-GB',
            country: 'uk',
            currency: 'GBP',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 8,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 8
                }
            },

            freeShippingFrom: 150,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'gb'
            ],
            displayTaxBeforeAddress: true

        },

        uk_kiosk: {
            name: 'United Kingdom (en) kiosk',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            country: 'uk',
            currency: 'GBP',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 0,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },

            freeShippingFrom: 0,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'gb'
            ],
            displayTaxBeforeAddress: true

        },

        ch_it: {
            name: 'Swiss (it) Italy',
            locale: 'it-IT',
            country: 'ch',
            currency: 'EUR',
            language: 'it',
            phone: '+3232014974',
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'it'
            ]
        },

        ch_de: {
            name: 'Swiss (de) Germany',
            locale: 'de-DE',
            country: 'ch',
            currency: 'EUR',
            language: 'de',
            phone: '+3232014974',
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'de'
            ]
        },

        ch_en: {
            name: 'Swiss (en) English',
            locale: 'en-EN',
            country: 'ch',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'en'
            ]
        },

        ch_fr: {
            name: 'Swiss (fr) France',
            locale: 'fr-FR',
            country: 'ch',
            currency: 'EUR',
            language: 'fr',
            phone: '+3232014974',
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'fr'
            ]
        },

        eu: {
            name: 'Europe (en)',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            hreflang: 'x-default',
            country: 'eu',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 15,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 15
                }
            },

            freeShippingFrom: -1,
            vatPercentage: 20,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'at', 'bg', 'hr', 'cy', 'cz', 'dk',
                'ee', 'fi', 'gr', 'hu', 'ie',
                'it', 'lv', 'lt', 'lu', 'mt',  'pl',
                'pt', 'ro', 'sk', 'si', 'es', 'se'
            ],
            displayTaxBeforeAddress: true

        },
        eu_es: {
            name: 'Europe (es) Spain',
            locale: 'en-ES',
            hreflang: 'x-default',
            country: 'eu',
            currency: 'EUR',
            language: 'es',
            phone: '+3232014974',
            shippingCost: 15,
            freeShippingFrom: -1,
            vatPercentage: 20,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Default shipping',
                amount: {
                    value: 8
                }
            },
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'es'
            ]
        },

        eu_it: {
            name: 'Europe (it) Italy',
            locale: 'it-IT',
            hreflang: 'it-IT',
            country: 'eu',
            currency: 'EUR',
            language: 'it',
            phone: '+3232014974',
            vatPercentage: 20,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Default shipping',
                amount: {
                    value: 8
                }
            },
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'it'
            ]
        },

        eu_kiosk: {
            name: 'Europe (en) kiosk',
            locale: 'en-GB',
            checkoutLocale: 'en_US',
            country: 'eu',
            currency: 'EUR',
            language: 'en',
            phone: '+3232014974',
            vatPercentage: 20,
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'at', 'bg', 'hr', 'cy', 'cz', 'dk',
                'ee', 'fi', 'gr', 'hu', 'ie',
                'it', 'lv', 'lt', 'lu', 'mt',  'pl',
                'pt', 'ro', 'sk', 'si', 'es', 'se'
            ],
            displayTaxBeforeAddress: true

        },

        ca_en: {
            name: 'Canada (EN)',
            locale: 'en-CA',
            country: 'ca',
            currency: 'CAD',
            language: 'en',
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'ca'
            ]
        },

        ca_fr: {
            name: 'Canada (FR)',
            locale: 'fr-CA',
            country: 'ca',
            currency: 'CAD',
            language: 'fr',
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'ca'
            ]
        },

        us: {
            name: 'United states (en)',
            locale: 'en-US',
            checkoutLocale: 'en_US',
            hreflang: 'en-US',
            country: 'us',
            currency: 'USD',
            language: 'en',
            phone: '+3232014974',
            shippingCost: 20,
            default_shipping_method: {
                carrier_code: 'tablerate',
                method_code: 'bestway',
                carrier_title: 'Standaard verzending',
                amount: {
                    value: 20
                }
            },
            vatPercentage: -1,
            freeShippingFrom: 250,
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'us'
            ],
            states: [
                { "id":1, "name":"Alabama", "code":"AL" },
                //{ "id":2, "name":"Alaska", "code":"AK" },
                //{ "id":3, "name":"American Samoa", "code":"AS" },
                { "id":4, "name":"Arizona", "code":"AZ" },
                { "id":5, "name":"Arkansas", "code":"AR" },
                //{ "id":6, "name":"Armed Forces Africa", "code":"AE" },
                //{ "id":7, "name":"Armed Forces Americas", "code":"AA" },
                //{ "id":8, "name":"Armed Forces Canada", "code":"AE" },
                //{ "id":9, "name":"Armed Forces Europe", "code":"AE" },
                //{ "id":10, "name":"Armed Forces Middle East", "code":"AE" },
                //{ "id":11, "name":"Armed Forces Pacific", "code":"AP" },
                { "id":12, "name":"California", "code":"CA" },
                { "id":13, "name":"Colorado", "code":"CO" },
                { "id":14, "name":"Connecticut", "code":"CT" },
                { "id":15, "name":"Delaware", "code":"DE" },
                { "id":16, "name":"District of Columbia", "code":"DC" },
                //{ "id":17, "name":"Federated States Of Micronesia", "code":"FM" },
                { "id":18, "name":"Florida", "code":"FL" },
                { "id":19, "name":"Georgia", "code":"GA" },
                //{ "id":20, "name":"Guam", "code":"GU" },
                //{ "id":21, "name":"Hawaii", "code":"HI" },
                { "id":22, "name":"Idaho", "code":"ID" },
                { "id":23, "name":"Illinois", "code":"IL" },
                { "id":24, "name":"Indiana", "code":"IN" },
                { "id":25, "name":"Iowa", "code":"IA" },
                { "id":26, "name":"Kansas", "code":"KS" },
                { "id":27, "name":"Kentucky", "code":"KY" },
                { "id":28, "name":"Louisiana", "code":"LA" },
                { "id":29, "name":"Maine", "code":"ME" },
                //{ "id":30, "name":"Marshall Islands", "code":"MH" },
                { "id":31, "name":"Maryland", "code":"MD" },
                { "id":32, "name":"Massachusetts", "code":"MA" },
                { "id":33, "name":"Michigan", "code":"MI" },
                { "id":34, "name":"Minnesota", "code":"MN" },
                { "id":35, "name":"Mississippi", "code":"MS" },
                { "id":36, "name":"Missouri", "code":"MO" },
                { "id":37, "name":"Montana", "code":"MT" },
                { "id":38, "name":"Nebraska", "code":"NE" },
                { "id":39, "name":"Nevada", "code":"NV" },
                { "id":40, "name":"New Hampshire", "code":"NH" },
                { "id":41, "name":"New Jersey", "code":"NJ" },
                { "id":42, "name":"New Mexico", "code":"NM" },
                { "id":43, "name":"New York", "code": "NY" },
                { "id":44, "name":"North Carolina", "code":"NC" },
                { "id":45, "name":"North Dakota", "code":"ND" },
                //{ "id":46, "name":"Northern Mariana Islands", "code":"MP" },
                { "id":47, "name":"Ohio", "code":"OH" },
                { "id":48, "name":"Oklahoma", "code":"OK" },
                { "id":49, "name":"Oregon", "code":"OR" },
                //{ "id":50, "name":"Palau", "code":"PW" },
                { "id":51, "name":"Pennsylvania", "code":"PA" },
                //{ "id":52, "name":"Puerto Rico", "code":"PR" },
                { "id":53, "name":"Rhode Island", "code":"RI" },
                { "id":54, "name":"South Carolina", "code":"SC" },
                { "id":55, "name":"South Dakota", "code":"SD" },
                { "id":56, "name":"Tennessee", "code":"TN" },
                { "id":57, "name":"Texas", "code":"TX" },
                { "id":58, "name":"Utah", "code":"UT" },
                { "id":59, "name":"Vermont", "code":"VT" },
                //{ "id":60, "name":"Virgin Islands", "code":"VI" },
                { "id":61, "name":"Virginia", "code":"VA" },
                { "id":62, "name":"Washington", "code":"WA" },
                { "id":63, "name":"West Virginia", "code":"WV" },
                { "id":64, "name":"Wisconsin", "code":"WI" },
                { "id":65, "name":"Wyoming", "code":"WY" },

            ],
            displayTaxBeforeAddress: false


        },
        us_kiosk: {
            name: 'United states (en) kiosk',
            locale: 'en-US',
            checkoutLocale: 'en_US',
            hreflang: 'en-US',
            country: 'us',
            currency: 'USD',
            language: 'en',
            default_shipping_method: {
                carrier_code: 'kiosk',
                method_code: 'kiosk',
                carrier_title: 'Gratis verzending',
                amount: {
                    value: 0
                }
            },
            vatPercentage: -1,
            phone: '+3232014974',
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            allowedCountries: [
                'us'
            ],
            freeShippingFrom: -1,
            shippingCost: 0,
            states: [
                { "id":1, "name":"Alabama", "code":"AL" },
                //{ "id":2, "name":"Alaska", "code":"AK" },
                //{ "id":3, "name":"American Samoa", "code":"AS" },
                { "id":4, "name":"Arizona", "code":"AZ" },
                { "id":5, "name":"Arkansas", "code":"AR" },
                //{ "id":6, "name":"Armed Forces Africa", "code":"AE" },
                //{ "id":7, "name":"Armed Forces Americas", "code":"AA" },
                //{ "id":8, "name":"Armed Forces Canada", "code":"AE" },
                //{ "id":9, "name":"Armed Forces Europe", "code":"AE" },
                //{ "id":10, "name":"Armed Forces Middle East", "code":"AE" },
                //{ "id":11, "name":"Armed Forces Pacific", "code":"AP" },
                { "id":12, "name":"California", "code":"CA" },
                { "id":13, "name":"Colorado", "code":"CO" },
                { "id":14, "name":"Connecticut", "code":"CT" },
                { "id":15, "name":"Delaware", "code":"DE" },
                { "id":16, "name":"District of Columbia", "code":"DC" },
                //{ "id":17, "name":"Federated States Of Micronesia", "code":"FM" },
                { "id":18, "name":"Florida", "code":"FL" },
                { "id":19, "name":"Georgia", "code":"GA" },
                //{ "id":20, "name":"Guam", "code":"GU" },
                //{ "id":21, "name":"Hawaii", "code":"HI" },
                { "id":22, "name":"Idaho", "code":"ID" },
                { "id":23, "name":"Illinois", "code":"IL" },
                { "id":24, "name":"Indiana", "code":"IN" },
                { "id":25, "name":"Iowa", "code":"IA" },
                { "id":26, "name":"Kansas", "code":"KS" },
                { "id":27, "name":"Kentucky", "code":"KY" },
                { "id":28, "name":"Louisiana", "code":"LA" },
                { "id":29, "name":"Maine", "code":"ME" },
                //{ "id":30, "name":"Marshall Islands", "code":"MH" },
                { "id":31, "name":"Maryland", "code":"MD" },
                { "id":32, "name":"Massachusetts", "code":"MA" },
                { "id":33, "name":"Michigan", "code":"MI" },
                { "id":34, "name":"Minnesota", "code":"MN" },
                { "id":35, "name":"Mississippi", "code":"MS" },
                { "id":36, "name":"Missouri", "code":"MO" },
                { "id":37, "name":"Montana", "code":"MT" },
                { "id":38, "name":"Nebraska", "code":"NE" },
                { "id":39, "name":"Nevada", "code":"NV" },
                { "id":40, "name":"New Hampshire", "code":"NH" },
                { "id":41, "name":"New Jersey", "code":"NJ" },
                { "id":42, "name":"New Mexico", "code":"NM" },
                { "id":43, "name":"New York", "code":"NY" },
                { "id":44, "name":"North Carolina", "code":"NC" },
                { "id":45, "name":"North Dakota", "code":"ND" },
                //{ "id":46, "name":"Northern Mariana Islands", "code":"MP" },
                { "id":47, "name":"Ohio", "code":"OH" },
                { "id":48, "name":"Oklahoma", "code":"OK" },
                { "id":49, "name":"Oregon", "code":"OR" },
                //{ "id":50, "name":"Palau", "code":"PW" },
                { "id":51, "name":"Pennsylvania", "code":"PA" },
                //{ "id":52, "name":"Puerto Rico", "code":"PR" },
                { "id":53, "name":"Rhode Island", "code":"RI" },
                { "id":54, "name":"South Carolina", "code":"SC" },
                { "id":55, "name":"South Dakota", "code":"SD" },
                { "id":56, "name":"Tennessee", "code":"TN" },
                { "id":57, "name":"Texas", "code":"TX" },
                { "id":58, "name":"Utah", "code":"UT" },
                { "id":59, "name":"Vermont", "code":"VT" },
                //{ "id":60, "name":"Virgin Islands", "code":"VI" },
                { "id":61, "name":"Virginia", "code":"VA" },
                { "id":62, "name":"Washington", "code":"WA" },
                { "id":63, "name":"West Virginia", "code":"WV" },
                { "id":64, "name":"Wisconsin", "code":"WI" },
                { "id":65, "name":"Wyoming", "code":"WY" },
            ],
            displayTaxBeforeAddress: false

        },
    },
    newsletter: {
        confirm : {
            builder_page_id : 'subscription-confirm'
        }
    },

    storeSelector: {
        hideCountry: [
            'ca',
            'ch',
            'it',
            'es',
            'eu_it',
            'eu_es'
        ]
    }
}

export default config;
