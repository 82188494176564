const getProductBreadcrumbs = `
    query getProductBreadcrumbs($productId: Int!, $categoryIds: [String]!) {
        getProductBreadcrumbs(
            productId: $productId, 
            categoryIds: $categoryIds
        ) {
            category {
                label
                path
            }
            product
        }
    }
`;
export default getProductBreadcrumbs;