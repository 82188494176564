import { catalogAbstract } from '@kega/sps-connector';

import getCategoryListQuery from '../queries/getCategoryList.gql';
import getCategoryQuery from '../queries/getCategory.gql';
import getProductDetailsQuery from '../queries/getProductDetails.gql';
import getColorRelatedProducts from '../queries/getColorRelatedProducts.gql';
import getUpsellProducts from '../queries/getUpsellProducts.gql';
import getProducts from "../queries/getProducts";
import getProductBreadcrumbs from "../queries/getProductBreadcrumbs.gql";

export default ({ client }) => {
    const catalog = Object.create(catalogAbstract);

    catalog.getCategories = ({ parent_id }, options) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getCategoryListQuery(options?.custom_attributes),
                variables: { parent_id: String(parent_id) }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.categoryList);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    catalog.getCategory = ({ url_path }, options) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getCategoryQuery(options?.custom_attributes),
                variables: { url_path: String(url_path) }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.categoryList[0]);

            }).catch((error) => {
                reject(error);
            });

        });
    }

    catalog.getProduct = ({ url_key }, options) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getProductDetailsQuery(options?.custom_attributes),
                variables: {
                    url_key
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                // Custom attribute mapping
                /*const mapped_attributes = options?.custom_attributes.map((attribute) => {
                    return {
                        [attribute]: data.products.items[0][attribute],
                    };
                });
                const response = {
                    ...data.products.items[0],
                    custom_attributes: mapped_attributes
                };*/

                resolve(data.products.items[0] || null);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    catalog.getProducts = (url_keys, options) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: getProducts(options?.custom_attributes),
                variables: {
                    url_key: url_keys
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data.products.items || null);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    catalog.getColorRelatedProducts = (sku) => {
        return new Promise((resolve, reject) => {
            const payload = {
                query: getColorRelatedProducts,
                variables: {
                    sku: sku
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data || null);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    catalog.getUpsellProducts = (sku) => {
        return new Promise((resolve, reject) => {
            const payload = {
                query: getUpsellProducts,
                variables: {
                    sku: sku
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data || null);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    catalog.getProductBreadcrumbs = (productId, categories) => {
        return new Promise((resolve, reject) => {
            const payload = {
                query: getProductBreadcrumbs,
                variables: {
                    productId: productId,
                    categoryIds: categories
                }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {

                if (errors) {
                    reject(errors);
                }

                resolve(data?.getProductBreadcrumbs || null);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    return catalog;
};
