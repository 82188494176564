import { useCart, useClearCart, useConfig, useCustomer } from "@kega/sps-core";
import { useNavigate } from "react-router-dom";

import useCartFromOrder from "../checkout/useCartFromOrder";
import useCheckoutData from "../checkout/useCheckoutData";
import useStoreViews from "../useStoreViews";

const useAdyenCheckout = () => {
    const { uid } = useCustomer();
    //const { ecommerceEvent } = useTagManager();
    const { cartId, items, totals } = useCart();
    const config = useConfig();
    const { cartFromOrder } = useCartFromOrder();
    const { lastOrderId } = useCheckoutData();
    const clearCart = useClearCart();
    const navigate = useNavigate();
    const { getUrl } = useStoreViews();

    const onPaymentCompleted = async (result = {}, paymentMethod = null) => {
        const { resultCode, isFinal } = result;
        const resultKey = resultCode.toLowerCase();

        if (isFinal && paymentMethod === 'free') {
            navigate(getUrl('/checkout/success'));
        }

        if (resultKey === 'authorised' || resultKey === 'pending' || resultKey ===  'received' || resultKey === 'success') {
            try {

                // ecommerceEvent('purchase', {
                //     transaction_id: cartId,
                //     affiliation: config.get('baseSiteId'),
                //     value: totals?.total?.value,
                //     currency: config.get('currency'),
                //     items: items.map(({ quantity=0, product={}, totalPrice }) => {
                //
                //         return {
                //             item_id: product?.code,
                //             item_name: product?.name,
                //             quantity: quantity,
                //             item_brand: product?.manufacturer,
                //             price: totalPrice?.value
                //         }
                //
                //     })
                // });

            } catch (error) {
                console.log('[order]', error);
            }

            navigate(getUrl('/checkout/success'));
        } else if (resultCode === 'Cancelled') {
            if (lastOrderId) {
                await cartFromOrder(lastOrderId);
            }   

            navigate(getUrl('/checkout/payment'), { state: { error: 'cancelled' } });
        } else if (resultKey === 'refused') {
            if (lastOrderId) {
                await cartFromOrder(lastOrderId);
            }

            navigate(getUrl('/checkout/payment'), { state: { error: 'refused' } });
        }  else if (resultKey === 'Failed') {
            navigate(getUrl('/checkout/payment'), { state: { error: 'failed' } });
        } else {
            // error
        }
    };

    return {
        onPaymentCompleted
    }
};

export default useAdyenCheckout;
