import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, Loader } from '@kega/sps-elements';
import { useConfig } from "@kega/sps-core";

import { t } from '../../lib/translations';

import useStoreViews from "../../hooks/useStoreViews";
import useSystemMessage from "../../hooks/useSystemMessage";
import useInputModifiers from "../../hooks/form/useInputModifiers";
import useInputValidation from "../../hooks/form/useInputValidation";
import useCheckoutData from "../../hooks/checkout/useCheckoutData";

import { Button, Input, Checkbox, Field } from "../form";
import { Row } from "../grid";
import CountryDropdown from "../country_dropdown/CountryDropdown";
import PlacesInput from "../places_input/PlacesInput";
import StateDropdown from "../state_dropdown/StateDropdown";

import classNames from "classnames";
import classes from './AddressForm.module.css';

const AddressForm = ({ address = null, endpoint = null, redirect = true, saving = false, buttonTitle = "", onSuccess = "", showSaveAs = true, checkoutForm = false, parentCallback, loggedInCheckoutForm = false, billingAddressForm = false, selected = null, children, fetchLoading, onSetBillingAddressData = null, setEmailValid, emailValid = false, emailRef = null, formParentRef = null, isAutoFocused = false }) => {
    const navigate = useNavigate();
    const { getUrl, currentStoreView, currentStoreCode } = useStoreViews();
    const { normalizeSpecialChars } = useInputModifiers();
    const { setSystemSuccessMessage } = useSystemMessage();
    const { firstname, lastname, street, city, zipcode, company, telephone, requiredField, containsNoString, email: emailValidation,  addressValidation, taxvat  } = useInputValidation();
    const { checkoutCreateAccountData, setCheckoutAccountData } = useCheckoutData();

    const { country } = currentStoreView;
    const isUS = currentStoreCode === 'us' || currentStoreCode === 'us_kiosk';

    const countryFieldRef = useRef();
    const postcodeRef = useRef();
    const telephoneRef = useRef();
    const formRef = useRef();

    const config = useConfig();

    const allowedCountries = config.get('allowedCountries');
    const billingAddressCountries = billingAddressForm ? config.get('billingCountries') : null;

    const states = config.get('states') ?? []

    const currentCountry = allowedCountries.length === 1 ? allowedCountries[0] : '';

    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const [inputs, setInputs] = useState({
        id: address?.id ?? null,
        firstname: address?.firstname ?? '',
        lastname: address?.lastname ?? '',
        street: Array.isArray(address?.street) ? (typeof address.street[0] !== 'undefined' ? address.street[0] : '') : address?.street,
        street_number: Array.isArray(address?.street) ? (typeof address.street[1] !== 'undefined' ? address.street[1] : '') : address?.street_number,
        company: address?.company ?? '',
        postcode: address?.postcode ?? '',
        city: address?.city ?? '',
        country_code: address?.country ? address.country.code : currentCountry,
        telephone: address?.telephone ?? '',
        vat_id: address?.vat_id ?? '',
        regionId:  address?.region ? address?.region?.region_id : (address?.regionId ? address.regionId : null),
        default_billing: !!address?.default_billing,
        default_shipping: !!address?.default_shipping,
        state: address?.state ?? null
    });

    useEffect(() => {
        if (checkoutForm) {
            if (address) {
                setInputs(prevInputs => ({
                    ...prevInputs,
                    id:               address.id ?? prevInputs.id,
                    firstname:        address.firstname ?? prevInputs.firstname,
                    lastname:         address.lastname ?? prevInputs.lastname,
                    street:           Array.isArray(address?.street) ? (typeof address.street[0] !== 'undefined' ? address.street[0] : (address?.street || prevInputs.street)) : (address?.street || prevInputs.street),
                    street_number:    Array.isArray(address?.street) ? (typeof address.street[1] !== 'undefined' ? address.street[1] : (address?.street_number || prevInputs.street_number)) : (address?.street_number || prevInputs.street_number),
                    company:          address.company ?? prevInputs.company,
                    vat_id:           address.vat_id ?? prevInputs.vat_id,
                    postcode:         address.postcode ?? prevInputs.postcode,
                    city:             address.city ?? prevInputs.city,
                    country_code:     address.country ? address.country.code : prevInputs.country_code,
                    regionId :        address?.region ? address?.region?.region_id : prevInputs.regionId,

                    telephone:        address.telephone ?? prevInputs.telephone,
                    default_billing:  prevInputs.default_billing,
                    default_shipping: prevInputs.default_shipping,
                    state:            prevInputs.state
                }));
            }
        }
    }, [address])

    const onChange = (e) => {

        const { target: { name, value, form } } = e;
        const newInputs = {
            ...inputs,
            [name]: value
        };

        setInputs(newInputs);

        updateParent();
    }

    const updateParent = (address = null, isUs = false) => {

        if (!address) {
            address = { ...inputs };
        }

        if (onSetBillingAddressData) {
            onSetBillingAddressData( address );
        }
    }

    const onCountryChange = (e) => {
        onChange(e);

        setTimeout(() => {
            telephoneRef.current?.triggerChange();
            //postcodeRef.current?.triggerChange();

        }, 100)
    }

    const onPlaceChange = (place) => {
        const newInputs = {
            ...inputs
        };

        newInputs.street = '';

        const components = place.address_components ?? [];
        let addressUpdates = {};

        let stateCode = null;
        let searchState = null;

        components.forEach((component) => {
            component.types.forEach((type) => {

                switch (type) {
                    case 'locality':
                    case 'postal_town':
                    case 'administrative_area_level_2':
                        addressUpdates.city = component.long_name;
                        break;
                    case 'route':
                        addressUpdates.street = component.long_name;
                        break;
                    case 'postal_code':
                        addressUpdates.postcode = component.long_name;
                        break;
                    case 'country':
                        addressUpdates.country_code = component.short_name;
                        break;
                    case 'street_number':
                        addressUpdates.street_number = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        stateCode = component.short_name;
                        searchState = states.filter(function(item){
                            return item.code === stateCode;
                        }).pop();
                        if (searchState) {
                            addressUpdates.regionId = searchState.id;
                        }

                        break;
                }
            });
        });

        setInputs(inputs => ({
            ...inputs,
            ...addressUpdates
        }));
        
        setTimeout(() => {
            postcodeRef.current?.triggerChange();
        } ,500)


        if (addressUpdates.city) {

            document.getElementById('city').focus();
            document.getElementById('city').blur();
        }

        updateParent(inputs, isUS);
    }

    const isCheckoutForm = () => {
        return checkoutForm || billingAddressForm;
    }

    const isGuestEmailValid = () => {
        if (billingAddressForm) {
            return true;
        }

        let isEmailValid = emailValid;

        if (emailRef?.current) {
            setEmailValid(!emailValidation(emailRef?.current?.ref.value));
            isEmailValid = !emailValidation(emailRef?.current?.ref.value);
        }

        return (loggedInCheckoutForm || !isCheckoutForm()) ? true : isEmailValid;
    }

    const OnSubmit = async ({ valid }) => {
        const isEmailValid = isGuestEmailValid();

        if (valid && isEmailValid) {
            setLoading(true);
            const endPoint = (address) => {
                return billingAddressForm ? parentCallback(address) : endpoint(address);
            }

            endPoint(inputs).then(() => {
                if (!isCheckoutForm()) {
                    setSystemSuccessMessage(onSuccess);
                }
                setLoading(false);

                if (redirect) {
                    navigate(getUrl('account/addressbook/overview'));
                }

                if (isCheckoutForm()) {
                    setCheckoutAccountData({
                        ...checkoutCreateAccountData,
                        firstname: inputs?.firstname,
                        lastname: inputs?.lastname
                    })
                }
            }).then(() => {
                setLoading(false);

                if (parentCallback && !billingAddressForm) {
                    parentCallback();
                }
            });
        } else {
            setIsFormValid(false);

            if (formRef?.current) {
                const scrollElement = !isEmailValid && emailRef?.current ? emailRef?.current?.ref :  formRef?.current?.ref;
                scrollElement.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        }
    }

    return (
        <Form onSubmit={OnSubmit} ref={!formParentRef ? formRef : formParentRef} className={classNames(checkoutForm && classes.address_form, loggedInCheckoutForm && classes.checkout_modal_address_form)}>
            <Row>
                <Field
                    className={loggedInCheckoutForm ? classNames(classes.checkout_modal_address, classes.firstname) : ""}
                    md={loggedInCheckoutForm ? 6 : 12}
                >
                    <Input
                        name="firstname"
                        label={t('customer.address.form.firstname')}
                        placeholder={t('customer.address.form.firstname')}
                        value={inputs.firstname}
                        onChange={onChange}
                        modifiers={[ normalizeSpecialChars() ]}
                        rules={[ ...firstname, requiredField ]}
                        required
                        autoFocus={isAutoFocused} />
                </Field>
                <Field
                    className={loggedInCheckoutForm ? classNames(classes.checkout_modal_address, classes.lastname) : ""}
                    md={loggedInCheckoutForm ? 6 : 12}
                >
                    <Input
                        name="lastname"
                        id="lastname"
                        label={t('customer.address.form.lastname')}
                        placeholder={t('customer.address.form.lastname')}
                        value={inputs.lastname}
                        onChange={onChange}
                        modifiers={[ normalizeSpecialChars() ]}
                        rules={[ ...lastname, requiredField ]}
                        required/>
                </Field>
            </Row>

            <Row>
                <Field>
                    <Input
                        name="telephone"
                        label={t('customer.address.form.telephone')}
                        placeholder={t('customer.address.form.telephone')}
                        value={inputs.telephone}
                        onChange={onChange}
                        ref={telephoneRef}
                        required
                        rules={[ telephone(countryFieldRef) ]}/>
                </Field>
            </Row>
            <Row>
                <Field>
                    <Input
                        name="company"
                        label={t('customer.address.form.company')}
                        placeholder={t('customer.address.form.company')}
                        value={inputs.company ? inputs.company : ''}
                        onChange={onChange}
                        rules={[ ...company ]}/>
                </Field>
            </Row>

            { (billingAddressForm || (!checkoutForm && !loggedInCheckoutForm)) &&
              <Row>
                  <Field>
                      <Input
                          name="vat_id"
                          label={t('customer.address.form.taxvat')}
                          placeholder={t('customer.address.form.taxvat')}
                          value={inputs.vat_id}
                          onChange={onChange}
                          rules={[ ...taxvat ]}/>
                  </Field>
              </Row>
            }
            { (billingAddressForm || country === 'eu') &&
            <Row>
                <Field>
                    <CountryDropdown
                        name="country_code"
                        ref={countryFieldRef}
                        label={t('customer.address.form.country_code')}
                        value={inputs.country_code}
                        customAllowedCountries={billingAddressCountries}
                        onChange={onCountryChange}
                        required/>
                </Field>
            </Row>
            }
            <Row>
                <Field>
                    <PlacesInput selectedCountry={(country === 'eu' || billingAddressForm) ? inputs.country_code : null} onPlaceChange={onPlaceChange} name="searchaddress" label={t('customer.address.form.search')} />
                </Field>
            </Row>
            <Row className={classNames(classes.street_row, isUS && classes.us_street_row)}>
                <Field xs={8}>
                    <Input
                        name="street"
                        label={t('customer.address.form.address')}
                        placeholder={t('customer.address.form.address')}
                        value={inputs.street}
                        onChange={onChange}
                        rules={[ ...street, requiredField, containsNoString ]}
                        required />
                </Field>
                <Field xs={4}>
                    <Input
                        name="street_number"
                        label={t('customer.address.form.address_number')}
                        placeholder={t('customer.address.form.address_number')}
                        value={inputs.street_number}
                        onChange={onChange}
                        {...((country === 'nl' || country === 'be' || country === 'fr' || country === 'de') && {
                            required: true,
                            rules: [requiredField]
                        })} />
                </Field>
            </Row>
            <Row>
                <Field>
                    <Input
                        name="postcode"
                        label={t('customer.address.form.postal_code')}
                        placeholder={t('customer.address.form.postal_code')}
                        value={inputs.postcode}
                        onChange={onChange}
                        ref={postcodeRef}
                        required
                        rules={[ zipcode(countryFieldRef), requiredField ]} />
                </Field>
                <Field className={classNames(classes.city, loggedInCheckoutForm ? classes.city_checkout_form : classes.city)} md={checkoutForm ? 12 : 8}>
                    <Input
                        name="city"
                        id="city"
                        label={t('customer.address.form.city')}
                        placeholder={t('customer.address.form.city')}
                        value={inputs.city}
                        onChange={onChange}
                        rules={[ ...city, requiredField ]}
                        required/>
                </Field>
            </Row>
            {country === 'us' &&
            <Row>
                <Field className={classes.state} md={12}>
                    <StateDropdown name="regionId" label={t('customer.address.form.state')} value={inputs.regionId} onChange={onChange} required />
                </Field>
            </Row>
            }
            { (!billingAddressForm && country !== 'eu') &&
            <Row>
                <Field>
                    <CountryDropdown
                        name="country_code"
                        ref={countryFieldRef}
                        value={inputs.country_code}
                        customAllowedCountries={billingAddressCountries}
                        onChange={onCountryChange}
                        required/>
                </Field>
            </Row>
            }

            {
                showSaveAs && !billingAddressForm &&
                <Row className={loggedInCheckoutForm ? classes.checkbox_row : ""}>
                    <Field xs={12} className={classNames(classes.check_btn, loggedInCheckoutForm ? classes.check_checkout_btn : classes.check_btn)}>
                        <Checkbox name="default_shipping" label={t('customer.address.form.use_as_default_shipping')} checked={inputs.default_shipping} onChange={onChange} />
                    </Field>
                    <Field xs={12} className={classNames(classes.check_btn, loggedInCheckoutForm ? classes.check_checkout_btn : classes.check_btn)}>
                        <Checkbox name="default_billing" label={t('customer.address.form.use_as_default_billing')} checked={inputs.default_billing} onChange={onChange} />
                    </Field>
                </Row>
            }

            {
                !checkoutForm &&
                <Row>
                    <Field className={loggedInCheckoutForm ? classes.save_checkout_btn_wrapper : ""} md={checkoutForm ? 12 : 4}>
                        <Button className={classNames(classes.save_btn, loggedInCheckoutForm ? classes.save_checkout_btn : classes.save_btn)} type="submit" loading={loading} variant="primary">
                            {t(buttonTitle)}
                        </Button>
                    </Field>
                </Row>
            }

            {
                checkoutForm && !billingAddressForm &&
                    <>
                        {
                            fetchLoading
                                ? <Loader variant="primary" />
                                : children
                        }
                        <Row>
                            <Field className={classes.save_checkout_btn_wrapper} md={12} >
                                <Button
                                    className={classNames(classes.save_btn)}
                                    type="submit"
                                    variant="primary"
                                    loading={loading || saving}
                                    disabled={!selected || loading}

                                >
                                    {t('checkout.steps.shipment.btn_next')}
                                </Button>
                            </Field>
                        </Row>
                    </>
            }
        </Form>
    );
}

export default AddressForm;
