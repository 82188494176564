import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useCustomer } from '@kega/sps-core';

import useStoreViews from "../../../hooks/useStoreViews";

import { WishlistIcon } from "../../../components/icons";

import classes from './Wishlist.module.css';

const Customer = () => {
    const { getUrl } = useStoreViews();
    const { authenticated } = useCustomer();
    const wishlistLength = useSelector((state) => state.wishlist.items_count);

    return (
        <div className={classes.root}>
            <NavLink className={classes.container} to={getUrl('/account/wishlist')}>
                <WishlistIcon title={"Customer Wishlist"} height="16" width="18" />
                {
                    (authenticated && wishlistLength > 0) && (
                        <span className={classes.counter}>{wishlistLength}</span>
                    )
                }
            </NavLink>
        </div>
    )
}
export default Customer;