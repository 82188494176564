import { useState, useEffect } from 'react';
import { Builder } from '@builder.io/react/lite';

import classNames from 'classnames';
import classes from './VideoOverlay.module.css';

export const VideoOverlay = () => {
    const [ visible, setVisible ] = useState(true);
    const [ videoEnded, setVideoEnded ] = useState(false);

    useEffect(() => {
        const previouslyVisited = localStorage.getItem('previouslyVisited');
        if (previouslyVisited) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, []);

    const onEnded = () => {
        setVideoEnded(true);
        setTimeout(() => {
            setVisible(false);
            localStorage.setItem('previouslyVisited', 'true');
        }, 500);
    }

    if (!visible) { return null; }

    const isLive = !Builder.isEditing && !Builder.isPreviewing;

    return (
        <>
            {
                isLive ?
                    <div className={classNames(classes.root, videoEnded ? classes.close : null)}>
                        <section className={classNames(classes.wrapper, videoEnded ? classes.close : null)}>
                            <video className={classes.video} autoPlay muted playsInline onEnded={onEnded}>
                                <source type="video/webm" src="/video/transparent-video.webm" />
                                <source type="video/mp4" codecs="hvc1" src="/video/transparent-video.mov" />
                            </video>
                        </section>
                    </div>
                    :
                    <div className={classes.preview_inserted}>
                        Video overlay inserted
                    </div>
            }
        </>
        
    );
};

Builder.registerComponent(VideoOverlay, {
    name: "Video overlay",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    }
});
