import { useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Loader } from '@kega/sps-elements';
import { useUpdateCart, useRemoveFromCart, useConfig, useCartId } from '@kega/sps-core';
import { ProductImage } from '@kega/sps-components';

import { t } from "../../../lib/translations";

import useTagManager from '../../../hooks/useTagManager';
import useStoreViews from '../../../hooks/useStoreViews';
import useCheckoutData from "../../../hooks/checkout/useCheckoutData";
import useApplyCoupon from "../../../hooks/checkout/useApplyCoupon";
import useEstimateShippingMethods from "../../../hooks/checkout/useEstimateShippingMethods";

import Price from "../../../components/price/Price";
import { Button, CounterInput } from '../../../components/form';
import Modal from "../../../components/modal/Modal";

import classNames from 'classnames';
import classes from './CartItem.module.css';

const CartItem = ({ className = '', id, configurable_options = null, name, sku, url_key, price_range, prices, discounts, product, quantity, media_gallery, att_text_perfion_googlecolour, att_text_perfion_productgroupcode, is_preorder, preorder_date, personalised_value }) => {

    const { updateCart, loading: loadingUpdate } = useUpdateCart();
    const { removeItem, loading: loadingRemove } = useRemoveFromCart();

    const { ecommerceEvent } = useTagManager();
    const location = useLocation();
    const { extraCartData } = useCheckoutData();
    const { estimateShippingMethods } = useEstimateShippingMethods();

    const getCartId = useCartId();

    const cartId = getCartId();

    const config = useConfig();
    const { getUrl, currentStoreCode } = useStoreViews();
    const currency = config.get('currency');
    const { prefix_product } = config.get('url');
    const isCart = location.pathname.indexOf('/checkout/cart') >= 0;
    const modalRef = useRef();
    const counterInputRef = useRef();
    const isUS = currentStoreCode === 'us' || currentStoreCode === 'us_kiosk';

    const getDiscountAmount = (price) => {
        const regularPrice = price_range.minimum_price.regular_price.value;
        let amount = 0;

        if (price === regularPrice) {
            return amount;
        }

        if (regularPrice > 0 && price < regularPrice) {
            amount = regularPrice - price;
        }

        return parseFloat(Math.round(amount * 100) / 100);
    }

    const onQtyChange = (childData) => {
        clearTimeout(window.cartCloseTimer)

        if (childData === 0) {
            if (isCart) {
                openModal();
            }

            if (!isCart) {
                removeFromCart();
            }
        } else {
            updateCart({
                cartitem: {
                    uid: id
                },
                quantity: childData
            }, extraCartData);

            estimateShippingMethods({ cartId: cartId }).then( () => {}) ;

            try {
                const price = price_range.minimum_price.final_price?.value ? price_range.minimum_price.final_price?.value : price_range.minimum_price.regular_price.value;
                const discount = getDiscountAmount(price);
                const size = configurable_options.length > 0 ? configurable_options[0]?.value_label : '';

                const data = {
                    value: price,
                    currency: currency,
                    items: [{
                        item_id: sku,
                        item_name: name,
                        item_category: att_text_perfion_productgroupcode,
                        price: price,
                        discount: discount,
                        currency: currency,
                        quantity: quantity,
                        item_variant: size,
                        item_key: url_key,
                        item_color: att_text_perfion_googlecolour
                    }]
                }

                let eventType = childData > quantity ? 'add_to_cart' : 'remove_from_cart';

                ecommerceEvent(eventType, data);
            } catch (error) {
                console.log('error pushing to datalayer', error);
                //
            }
        }
    }

    const removeFromCart = () => {
        removeItem({
            cartitem: {
                uid: id
            }
        }, extraCartData).then((res) => {
            isCart ? closeModal() : ''
            estimateShippingMethods({ cartId: cartId }).then( () => {}) ;
        });

        try {
            const price = price_range.minimum_price.final_price?.value ? price_range.minimum_price.final_price?.value : price_range.minimum_price.regular_price.value;
            const discount = getDiscountAmount(price);
            const size = configurable_options.length > 0 ? configurable_options[0]?.value_label : '';

            const data = {
                value: price,
                currency: currency,
                items: [{
                    item_id: sku,
                    item_name: name,
                    item_category: att_text_perfion_productgroupcode,
                    price: price,
                    discount: discount,
                    currency: currency,
                    quantity: quantity,
                    item_variant: size,
                    item_key: url_key,
                    item_color: att_text_perfion_googlecolour
                }]
            }
            ecommerceEvent('remove_from_cart', data);
        } catch (error) {
            console.log('error pushing to datalayer', error);
            //
        }
    }

    const keepItem = () => {
        const input = counterInputRef.current?.querySelector('input');
        if (input) {
            input.value = 1;
            onQtyChange(1);
        }

        closeModal();
    }

    const openModal = () => {
        modalRef.current?.open();
    };

    const closeModal = () => {
        modalRef.current?.close();
    }

    const placeholder_price_range = {
        minimum_price: {
            regular_price: {
                currency,
                value: '123'
            }
        }
    }


    const price = prices.price ? prices.price.value : (price_range?.minimum_price.final_price?.value ? price_range?.minimum_price.final_price?.value : price_range?.minimum_price.regular_price.value);
    const rowTotalIncl = prices ? prices.row_total_including_tax.value : 0;
    const rowTotalExcl = prices ? prices.row_total.value : 0;

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.left}>
                <div className={classes.image}>
                    <Link to={getUrl(`${prefix_product}/${url_key}`)}>
                        <ProductImage
                            src={media_gallery ? media_gallery[0]?.url : '#'}
                            alt={name}
                            onClick={() => { getUrl(`${prefix_product} / ${url_key}`)}} />
                    </Link>
                </div>
                <div className={classes.details}>
                    <div className={classes.information}>
                        <Link to={getUrl(`${prefix_product}/${url_key}`)}>
                            {name}
                        </Link>
                        <div className={classes.attribute_options}>
                            {att_text_perfion_googlecolour} | {configurable_options ? configurable_options[0]?.value_label : ''}
                        </div>
                    </div>
                    {
                        ((is_preorder == 1 || is_preorder === 'Yes') && isCart) &&
                        <div className={classNames(classes.preorder, classes.caption_text)}>
                            {t('product.preorder.caption', { date: preorder_date || '?' })}
                        </div>
                    }

                    {
                        (personalised_value) &&
                        <div className={classNames(classes.personalised_order, classes.caption_text)}>
                            {t('product.personalised_order.cart.caption', { value: personalised_value })}
                            <br/>
                            {t('product.personalised_order.cart.text')}
                        </div>
                    }

                    <div className={classes.details_bottom}>
                        <div className={classes.qty_wrapper} ref={counterInputRef}>
                            {
                                loadingUpdate || loadingRemove
                                    ?
                                    <Loader size="24" />
                                    :
                                    <CounterInput
                                        name="qty"
                                        value={quantity}
                                        parentCallback={onQtyChange}
                                        variant="mini"
                                    />
                            }
                        </div>
                        {
                            price_range
                            &&
                            <Price
                                isCart={isCart}
                                rootClassName={classes.price_wrapper}
                                className={classes.cart_price}
                                price={ isUS ? rowTotalExcl : rowTotalIncl}
                                originalPrice={price_range.minimum_price.regular_price.value * quantity} />
                        }
                    </div>
                </div>
            </div>

            {
                isCart &&
                <Modal
                    modalClassName={classes.remove_item_modal}
                    title={t('checkout.cart.remove_item_title')}
                    ref={modalRef}
                    onClose={keepItem}
                    className={classes.icon_close}
                >
                    <div className={classes.remove_item_actions}>
                        <Button
                            variant="secondary"
                            className={classes.close_btn}
                            onClick={keepItem}
                        >
                            {t('checkout.cart.keep_item')}
                        </Button>
                        <Button
                            variant="primary"
                            className={classes.remove_btn}
                            onClick={removeFromCart}
                            loading={loadingUpdate || loadingRemove}
                        >
                            {t('checkout.cart.remove_item')}
                        </Button>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default CartItem;
