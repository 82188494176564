import adyenPaymentSessionQuery from "../queries/adyen/adyenPaymentSession.gql.js";
import adyenPaymentMethodsQuery from "../queries/adyen/adyenPaymentMethods.gql";
import adyenPaymentDetailsQuery from "../queries/adyen/adyenPaymentDetails.gql";
import adyenPaymentStatusQuery from "../queries/adyen/adyenPaymentStatus.gql";
import adyenConnectedTerminals from "../queries/adyen/adyenConnectedTerminals";
import adyenGiftcardPaymentQuery from "../queries/adyen/adyenGiftcardPayment.gql";

export default ({ client, tokens }) => {
    const api = {};

    api.getCheckoutSessionData = async ({ cartId }) => {
        return new Promise((resolve, reject) => {

            const payload = {
                query: adyenPaymentSessionQuery,
                variables: { cartId }
            };

            client.post('/graphql', payload).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data ?? {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    api.getPaymentMethods = async ({ cartId }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: adyenPaymentMethodsQuery,
                variables: { cartId }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data ?? {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    api.getPaymentDetails = async ( cartId, data ) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: adyenPaymentDetailsQuery,
                variables: {
                    cartId: cartId,
                    payload: data
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data ?? {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    api.getPaymentStatus = async ( cartId, orderId, customToken = null ) => {

        let token = '';
        if (customToken) {
            token = customToken;
        } else {
            const customerToken = await tokens.get('customer') ?? '';
            token = customerToken?.token;
        }

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: adyenPaymentStatusQuery,
                variables: {
                    cartId: cartId,
                    orderNumber: orderId
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data?.adyenPaymentStatus ?? {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    api.getConnectedTerminals = async ( cartId ) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: adyenConnectedTerminals,
                variables: { cartId }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data?.connectedTerminals ? data.connectedTerminals : {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    api.paymentGiftcard = async (cartId, orderNumber, details) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            };

            const payload = {
                query: adyenGiftcardPaymentQuery,
                variables: {
                    cartId,
                    orderNumber: orderNumber,
                    cardInfo: {
                        number: details?.encryptedCardNumber,
                        pin: details?.encryptedSecurityCode,
                        type: details?.type,
                        brand: details?.brand
                    }
                }
            };

            client.post('/graphql', payload, headers).then(({ data, errors }) => {
                if (errors) {
                    reject(errors);
                }

                resolve(data?.adyenGiftcardPayment ?? {});
            }).catch((error) => {
                reject(error);
            });

        });
    }

    return api;
};
