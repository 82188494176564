import { useDispatch, useSelector } from 'react-redux';

import {
    newsletterOverlayOpen,
    newsletterOverlayClose,
    setPageCount
} from '../redux/newsletter_overlay/actions';

const useNewsletterOverlay = () => {
    const dispatch = useDispatch();

    const { isNewsletterOverlayOpen, pageCount } = useSelector((state) => state.newsletter_overlay);

    const openNewsletterOverlay = () => { dispatch(newsletterOverlayOpen()); }
    const closeNewsletterOverlay = () => { dispatch(newsletterOverlayClose()); }
    const updatePageCount = (count) => { dispatch(setPageCount(count)); }

    return {
        isNewsletterOverlayOpen,
        pageCount,
        updatePageCount,
        openNewsletterOverlay,
        closeNewsletterOverlay
    };
}

export default useNewsletterOverlay;
