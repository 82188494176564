import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useConfig } from '@kega/sps-core';

import Skeleton from 'react-loading-skeleton';

import { Accordion, AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';

import useFetchColorRelatedProducts from "../../hooks/useFetchColorRelatedProducts";
import useTagManager from "../../hooks/useTagManager";
import useUi from "../../hooks/useUi";
import useMediaQuery from "../../hooks/useMediaQuery";
import useStoreConfig from "../../hooks/useStoreConfig";

import { t } from '../../lib/translations';
import { decodeUrl } from '../../lib/utils';

import { Col, Row, Container } from '../../components/grid';
import { ChevronIcon } from "../../components/icons";
import ColorRelated from "../../components/product/colorrelated/ColorRelated";
import Gallery from "./gallery/Gallery";
import NotFound from '../../pages/error/notfound/NotFound';
import SizeFit from "./sizefit/SizeFit";
import GeneralInfo from "./generalinfo/GeneralInfo";
import ProductBreadcrumb from '../../components/product_breadcrumb/ProductBreadcrumb';
import Info from './info/Info';
import ConfigurableOrder from './configurableorder/ConfigurableOrder';
import SimpleOrder from './simpleorder/SimpleOrder';
import BundleOrder from './bundleorder/BundleProduct';
import LoadingOrder from './loadingorder/LoadingOrder';
import ShippingReturns from "./shippingreturns/ShippingReturns";
import Help from "./help/Help";
import StructuredData from "./structureddata/StructuredData";
import Upsell from "../../components/product/upsell/Upsell";
import RelatedProducts from "../../components/product/relatedproducts/RelatedProducts";

import classes from "./ProductDetails.module.css"
import aa from "search-insights";

const ProductDetails = ({ product, loading }) => {
    const [colorRelated, setColorRelated] = useState([]);
    const [showRelatedProducts, setShowRelatedProducts] = useState(0);

    const { state } = useLocation();

    const { loading: colorLoading, fetch } = useFetchColorRelatedProducts();
    const { closeZoomGallery } = useUi();
    const { ecommerceEvent } = useTagManager();
    const config = useConfig();
    const { getConfig } = useStoreConfig();
    const { index } = config.get('algolia');
    const storeCode = config.get('storeCode');
    const formattedCode = storeCode.replace('_kiosk', '');

    const gallery = [];

    useEffect( async () => {
        const show  = await getConfig('display_related_products');
        setShowRelatedProducts(show);

    },[product])

    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(product?.sku);
            setColorRelated(response.result);
        }

        if (state?.color_related_products && colorRelated.length === 0) {
            setColorRelated(state?.color_related_products)
        }

        if (product.sku) {
            fetchContent();
        }

        document.body.classList.add(classes.product_view);

        try {
            if (loading) {
                ecommerceEvent('view_item', {
                    currency: config.get('currency'),
                    value: product.price_range ? product.price_range.minimum_price.final_price.value : null,
                    items: [{
                        item_id: product.sku,
                        item_name: product.name,
                        item_category: product.att_text_perfion_productgroupcode,
                        price: product.price_range ? product.price_range.minimum_price.final_price.value : null,
                        discount: '',
                        currency: config.get('currency')
                    }]
                });

                aa('viewedObjectIDs', {
                    eventName: 'View product',
                    index: index + formattedCode + '_products',
                    objectIDs: [product.sku]
                });
            }
        } catch (error) {
            //
        }

        return () => {
            document.body.classList.remove(classes.product_view);
            closeZoomGallery();
        }
    }, [product, setColorRelated]);



    if (!loading && product === null) { return <NotFound />; }

    product = product || {};

    const {
        name,
        image,
        variants = [],
        configurable_options = [],
        media_gallery = [],
        __typename: type
    } = product;

    let height = 1287,
        width = 919;

    const { match: matchTablet } = useMediaQuery(`(max-width: 768px) and (min-width: 640px)`);
    const { match: matchDesktop } = useMediaQuery(`(max-width: 1440px) and (min-width: 769px)`);
    const { match: matchMobile } = useMediaQuery(`(max-width: 640px)`);

    media_gallery.map((image) => {
        try {
            let imageSrc = decodeUrl(image?.url || '');
            const imageUrl = new URL(imageSrc);
            imageUrl.searchParams.set('width', width);
            imageUrl.searchParams.set('height', height);

            imageSrc = imageUrl.toString();

            let images = ['__s.', '__h.', '__c.'].some(el => image.url.includes(el));

            !images && gallery.push(imageSrc);
        } catch (error) {
            //console.error("Please check image URL in ProductDetails.js")
        }

    })

    if (matchTablet) {
        height = 515;
        width = 368;

    } else if (matchDesktop) {
        height = 1287;
        width = 919;
    }

    let imageSrc = decodeUrl(image?.url || '');

    try {
        const imageUrl = new URL(imageSrc);
        imageUrl.searchParams.set('width', width);
        imageUrl.searchParams.set('height', height);

        imageSrc = imageUrl.toString();
    } catch (error) { } // eslint-disable-line no-empty

    return (
        <>
            <Container margins g-xs={1} g-sm={3} g-md={2} g-xl={4} className={classes.root}>
                <StructuredData product={product} />
                <Row>
                    <Col sm={6} md={8} className={classes.gallery_container}>
                        <Gallery
                            images={gallery.length === 0 ? [imageSrc] : gallery}
                            width={width}
                            height={height}
                            alt={name}
                            matchGallerySlide={matchMobile}
                            loading={loading}
                            product={product}
                            product_page={true} />
                    </Col>
                    <Col sm={6} md={4} className={classes.product_info_container}>
                        <div className={classes.product_info}>
                            {!matchMobile && (
                                loading
                                    ?
                                    <Skeleton containerClassName={classes.breadcrumb_skeleton} />
                                    :
                                    <div className={classes.breadcrumb_desktop}>
                                        <ProductBreadcrumb productId={product?.id} categories={product?.categories} />
                                    </div>
                            )}
                            <Info product={product} loading={loading} />
                            {
                                loading || (colorLoading && colorRelated.length === 0)
                                    ?
                                    <Skeleton containerClassName={classes.color_skeleton} />
                                    :
                                    <ColorRelated product={product} colorRelated={colorRelated?.items ? colorRelated.items : colorRelated} color={colorRelated.currentColor ? colorRelated.currentColor : state?.color} className={classes.detail_colorrelated} />
                            }
                            {
                                loading
                                    ?
                                    <LoadingOrder />
                                    :
                                    <>
                                        {
                                            type === 'ConfigurableProduct'
                                            &&
                                            <ConfigurableOrder
                                                variants={variants}
                                                options={configurable_options}
                                                product={product} 
                                                gallery={gallery}/>
                                        }

                                        {
                                            type === 'SimpleProduct'
                                            &&
                                            <SimpleOrder product={product} gallery={gallery} />
                                        }

                                        {
                                            type === 'BundleProduct'
                                            &&
                                            <BundleOrder product={product} />
                                        }

                                    </>
                            }

                            <Accordion className={classes.accordion_container} onlyOne>
                                <AccordionItem className={classes.accordion_item} resizeOnOpen>
                                    <AccordionHeader className={classes.heading} role="heading">
                                        <h5>
                                            {t('product.info.accordion.product_details_title')}
                                        </h5>
                                        <AccordionIcon>
                                            <ChevronIcon title={"Product Details Accordion"} size={8} />
                                        </AccordionIcon>
                                    </AccordionHeader>
                                    <AccordionContent className={classes.content}>
                                        <GeneralInfo product={product} />
                                    </AccordionContent>
                                </AccordionItem>
                                <AccordionItem className={classes.accordion_item} resizeOnOpen>
                                    <AccordionHeader className={classes.heading} role="heading">
                                        <h5>
                                            {t('product.info.accordion.shipping_return_title')}
                                        </h5>
                                        <AccordionIcon>
                                            <ChevronIcon title={"Product Details Accordion"} size={8} />
                                        </AccordionIcon>
                                    </AccordionHeader>
                                    <AccordionContent className={classes.content}>
                                        <ShippingReturns />
                                    </AccordionContent>
                                </AccordionItem>
                                <AccordionItem className={classes.accordion_item} resizeOnOpen>
                                    <AccordionHeader className={classes.heading} role="heading">
                                        <h5>
                                            {t('product.info.accordion.help_title')}
                                        </h5>
                                        <AccordionIcon>
                                            <ChevronIcon title={"Product Details Accordion"} size={8} />
                                        </AccordionIcon>
                                    </AccordionHeader>
                                    <AccordionContent className={classes.content}>
                                        <Help />
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} className={classes.upsell_container}>
                        <Upsell parentSku={product?.sku} />
                    </Col>
                </Row>
            </Container>
            {
                (showRelatedProducts === true || showRelatedProducts === 1)
                &&
                <Row>
                    <Col sm={12} md={12}>
                        <RelatedProducts code={product?.sku} />
                    </Col>
                </Row>
            }
        </>
    )
}

export default ProductDetails;
