import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from "@kega/sps-core";

import useMediaQuery from '../../hooks/useMediaQuery';
import useCheckoutAgreements from "../..//hooks/checkout/useCheckoutAgreements";
import useStoreViews from '../../hooks/useStoreViews';
import useTagManager from "../../hooks/useTagManager";

import CheckoutSummary from './checkoutsummary/CheckoutSummary';
import CheckoutContainer from "./checkoutcontainer/CheckoutContainer";
import Progressbar from './progressbar/Progressbar';
import InfoBanner from './info_banner/InfoBanner';

import { Col, Row, Container } from '../../components/grid';

import classes from './Checkout.module.css';

const Checkout = ({ items, totals }) => {
    const [methodSelected, setMethodSelected] = useState(false);
    const [sending, setSending] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(null);

    const checkoutFormRef = useRef(null);
    const checkboxRef = useRef(null);

    const { match } = useMediaQuery(`(max-width: 1024px)`);
    const { getUrl } = useStoreViews();
    const { ecommerceEvent } = useTagManager();
    const navigate = useNavigate();
    const config = useConfig();

    const { getCheckoutAgreements } = useCheckoutAgreements();

    const defaultCurrency = config.get('currency') ?? 'EUR';

    const onButtonPress = async () => {
        if (!checkboxRef.current || checkboxRef.current.validity.valid) {
            setAcceptedTerms(true);
            setSending(true);

            checkoutFormRef.current.submit();
        } else {
            setAcceptedTerms(false);
        }
    };

    const onCheckboxChange = () => {
        setAcceptedTerms(checkboxRef.current.validity.valid);
    }

    const pushLayer = (totals, items) => {
        try {

            let data = {
                currency: defaultCurrency,
                value: totals.total,
                items: [
                ]
            }

            items.forEach( (item, index) => {
                const discounts = item.prices.discounts ?? null;

                data.items.push({
                    item_id: item.sku,
                    item_name: item.name,
                    affiliation: "",
                    coupon: discounts ? discounts[0].label : '',
                    discount: discounts ? discounts[0].amount.value : 0,
                    index: index,
                    item_brand: "",
                    item_category: item.att_text_perfion_productgroupcode,
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: item.configurable_options.length > 0 ? item.configurable_options[0].value_label : '',
                    location_id: "",
                    price: item.price_range.minimum_price.regular_price.value,
                    quantity: item.quantity
                })
            });

            ecommerceEvent('begin_checkout', data);
        } catch (error) {
            console.log('gtm error','begin_checkout', error)
            //
        }
    }

    useEffect(() => {
        // TODO fetch first?
        if (!items.length) {
            navigate(getUrl('/checkout/cart'));
        } else {

            getCheckoutAgreements();
            pushLayer(totals, items);
        }
    }, [])

    return (
        <div className={classes.root}>
            <Progressbar />
            <div className={classes.info_banner}>
                <InfoBanner />
            </div>
            <Container xl g-xs={2} g-lg={4}>
                <Row className={classes.checkout_row}>
                    <Col lg={6}>
                        <CheckoutContainer
                            setMethodSelected={setMethodSelected}
                            setSending={setSending}
                            acceptedTerms={acceptedTerms}
                            setAcceptedTerms={setAcceptedTerms}
                            onCheckboxChange={onCheckboxChange}
                            checkboxRef={checkboxRef}
                            checkoutFormRef={checkoutFormRef}
                            sending={sending}
                            methodSelected={methodSelected}
                            match={match} />

                    </Col>
                    <Col lg={6} className={classes.col}>
                        <CheckoutSummary
                            items={items}
                            match={match} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Checkout;
