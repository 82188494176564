import { useEffect } from "react";

import useGeoIp from "../../hooks/useGeoIp";
import useStoreViews from "../../hooks/useStoreViews";
import useUi from "../../hooks/useUi";

import { Col, Row } from "../grid";
import SelectModal from "./select_modal/SelectModal";

import { t, translateLanguage, regionNameInEnglish } from "../../lib/translations";
import { getSymbol } from "../../lib/currency";
import { strEquals } from "../../lib/utils";

import classes from "./StoreSelector.module.css";
import classNames from "classnames";

const StoreSelector = ({ className = '', colClassName = '', wrapperClassName = '' }) => {
    const { details: geoip, modalOpened, setModalOpened } = useGeoIp();
    const { currentStoreView } = useStoreViews();
    const { openStoreSelector, closeStoreSelector, isStoreSelectorOpen } = useUi();

    const rootClassName = classNames(classes.root, className);
    wrapperClassName = classNames(classes.wrapper, wrapperClassName);

    const isUserAgentBot = () => {
        return /bot|googlebot|crawler|spider|robot|crawling|slackbot/i.test(navigator.userAgent);
    };

    if (isUserAgentBot()) { return null; }

    useEffect(() => {
        if (!modalOpened
            && geoip.country_code
            && currentStoreView.country
            && !strEquals(geoip.country_code, currentStoreView.country, false)
        ) {
            openStoreSelector();
            setModalOpened(true);
        }
    }, [geoip]);


    return (
        <>
            <Row className={rootClassName}>
                <Col md="6" className={colClassName}>
                    <div className={wrapperClassName} onClick={openStoreSelector} role="button" tabIndex="0">
                        <span className={classes.label}>{t('store_selector.shipping_to')}</span>
                        <span className={classes.selector} role="button" tabIndex="0">
                            {regionNameInEnglish(currentStoreView.country)} ({getSymbol(currentStoreView.currency)})
                        </span>
                    </div>
                </Col>
                <Col md="6" className={colClassName}>
                    <div className={wrapperClassName} onClick={openStoreSelector} role="button" tabIndex="0">
                        <span className={classes.label}>{t('store_selector.language')}</span>
                        <span className={classes.selector} role="button" tabIndex="0">
                            {translateLanguage(currentStoreView.language)}
                        </span>
                    </div>
                </Col>
            </Row>
            <SelectModal
                closeStoreSelector={closeStoreSelector}
                isStoreSelectorOpen={isStoreSelectorOpen} />
        </>
    );
};

export default StoreSelector;
