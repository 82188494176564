import { useRequest, useStorefront } from '@kega/sps-core';

import { t } from '../lib/translations';
import useSystemMessage from "./useSystemMessage";

const useFetchProductBreadcrumbs = () => {
    const { api: { catalog } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetch = async (productId, categories) => {
        try {
            setRequest();

            const categoryIds = categories ? categories.map(({ uid }) => uid) : [];

            const result = await catalog.getProductBreadcrumbs(productId, categoryIds);
            
            if (result) {
                setSuccess();
                return result;
            }

            return [];
        } catch (error) {
            console.log('[fetchProductBreadcrumbs]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        fetch
    };
}

export default useFetchProductBreadcrumbs;
