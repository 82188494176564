import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { t } from '../../../lib/translations';
import { consoleLog } from "../../../lib/utils";

import useStoreViews from '../../../hooks/useStoreViews';
import useStoreStock from "../../../hooks/store_stock/useStoreStock";

import ColorOptions from '../../../components/product/options/color/ColorOptions';
import SizeOptionsBlocks from '../../../components/product/options/size/SizeOptionsBlocks';
import AddToCart from '../../../components/product/addtocart/AddToCart';
import WishlistButton from '../../../components/wishlist/wishlist_button/WishlistButton';
import SubscribeStockAlert from "../../../components/product/subscribe_stock_alert/SubscribeStockAlert";

import classNames from "classnames";
import classes from "./ConfigurableOrder.module.css"
import PersonalisedOrder from "../../../components/product/personalised_order/PersonalisedOrder";

const isLsAttribute = (attribute) => {
    return attribute.startsWith('ls_');
};

const getStockStatus = (product) => {
    return (product && product.stock_status === 'IN_STOCK');
};

const containsNumbers = (value) => {
    return /\d/.test(value);
};

const useOptions = (options = [], variants = [], selected) => { // eslint-disable-line no-unused-vars
    let weights = {
        'OS': 1,
        'XXS': 2,
        'XS': 3,
        'S': 4,
        'M': 5,
        'L': 6,
        'XL': 7,
        'XXL': 8
    };

    // Normalize options
    let allOptions = useMemo(() => {
        let allOptions = options.reduce((options, option) => {
            options[option.attribute_code] = {
                code: option.attribute_code,
                label: option.label,
                position: option.position,
                type: option.attribute_code + 'options',
                values: option.values.reduce((values, { uid, label, swatch_data, }) => {
                    values.push({
                        id: uid,
                        label: label,
                        value: swatch_data?.value,
                        disabled: false
                    })
                    return values.sort((a, b) => {
                        if (containsNumbers(a.label) && containsNumbers(b.label)) {
                            return (a.label > b.label) ? 1 : -1;
                        } else {
                            return (weights[a.label] > weights[b.label]) ? 1 : -1;
                        }
                    });
                }, [])
            };

            return options;
        }, {});

        return Object.values(allOptions);

    }, [options]);

    // Add stock status to swatches
    allOptions = useMemo(() => {
        let stockStatus = {};

        variants.forEach(({ attributes, product }) => {
            if (attributes && Array.isArray(attributes)) {
                attributes.every(({ code, uid }) => {
                    if (code === 'size') {
                        stockStatus[uid] = getStockStatus(product);
                        return false;
                    }
                    return true;
                });
            }
        });

        allOptions.every(({ code, values }, optionIndex) => {
            if (code === 'size') {
                values.forEach((value, valueIndex) => {
                    allOptions[optionIndex].values[valueIndex].stockStatus = (value.id && stockStatus.hasOwnProperty(value.id))
                        ? stockStatus[value.id]
                        : false;
                });

                return false;
            }

            return true;
        });

        return allOptions;
    }, [allOptions, variants]);

    return allOptions;
}

const useSelectedVariant = (variants = [], selected) => {

    return useMemo(() => {

        if (selected.length === 0) { return null }

        const allOutOfStock = variants.every(({ product }) => {
            return product.stock_status === 'OUT_OF_STOCK';
        })

        const variant = variants.find(({ attributes }) => {
            let attributesMatched = 0;

            Object.keys(selected).forEach((selectedCode) => {
                const selectedUid = selected[selectedCode];

                attributes.forEach(({ code, uid }) => {
                    if ((selectedCode === code && selectedUid === uid) || isLsAttribute(code)) {
                        attributesMatched++;
                    }
                });

            });

            return (attributesMatched === Object.keys(attributes).length)
        });

        return (variant?.product ? variant.product : null)

    }, [variants, selected]);

};

const ConfigurableOrder = ({ variants, options, product, gallery, giftBox = false }) => {

    const [selected, setSelected] = useState({});

    const { getStoreStock, cache } = useStoreStock();
    const orderOptions = useOptions(options, variants, selected);
    const variant = useSelectedVariant(variants, selected);
    const [showAlertButton, setShowAlertButton] = useState(variant && !getStockStatus(variant));

    const [currentCacheKey, setCurrentCacheKey] = useState(null);
    const [stockChecking, setStockChecking] = useState(false);
    const [selectedSizeLabel, setSelectedSizeLabel] = useState('');
    const [personalisedValue, setPersonalisedValue] = useState('');

    const { getUrl } = useStoreViews();
    const navigate = useNavigate();

    useEffect( () => {
        let allOutOfStock = true;
        variants.forEach( (variantItem) => {
            if(variantItem.product.stock_status === 'IN_STOCK') {
                allOutOfStock = false;
            }
        });

        consoleLog('All out of stock', allOutOfStock);

        if(allOutOfStock) {
            setShowAlertButton(true);
        }

    },[variants])

    useEffect(() => {
        const availableOptions = orderOptions.filter((option) => option.type === 'sizeoptions' && option.values.length === 1);
        if (availableOptions.length === 1) {
            const availableOption = availableOptions[0];
            const selectedSize = availableOption.values[0].id;
            setSelected((prevState) => ({ ...prevState, [availableOption.code]: selectedSize }));
            onOptionChange(availableOption.code, selectedSize);
        }
    }, [orderOptions]);

    const onOptionChange = (code, value) => {
        setCurrentCacheKey('none');
        setStockChecking(true);
        setSelected((selected) => {

            if (selected[code] === value) {
                let newSelected = { ...selected }
                return newSelected;
            } else {
                return {
                    ...selected,
                    [code]: value
                }
            }
        })

        if (product) {
            variants.forEach((variantItem) => {

                variantItem.attributes.forEach((attr) => {
                    if (attr.code === code && attr.uid === value) {

                        setSelectedSizeLabel(attr.label);
                        if (variantItem.product.stock_status === 'OUT_OF_STOCK') {
                            setStockChecking(false);
                            setShowAlertButton(true);
                            return;
                        }

                        getStoreStock(product.sku, variantItem.product.sku).then(() => {
                            setCurrentCacheKey(product.sku + '_' + variantItem.product.sku);

                            Object.keys(cache).forEach((cacheKey) => {
                                const current = cache[cacheKey];
                                orderOptions.forEach((orderOption) => {
                                    orderOption.values.forEach((value) => {
                                        if (value.id === current.uuid) {
                                            value.stockStatus = current.quantity > 0;

                                        }
                                    });
                                });
                            });

                            setStockChecking(false);

                            setShowAlertButton(
                                !(
                                    cache[product.sku + '_' + variantItem.product.sku] &&
                                    cache[product.sku + '_' + variantItem.product.sku].quantity > 0
                                )
                            );
                        });
                    }
                });
            });
        }
    }

    return (
        <div className={classes.root}>
            {
                orderOptions.map(({ code, type, values }) => {
                    return (
                        <div key={code} className={classNames(classes.option, giftBox && classes.giftbox_options)}>
                            {(type === 'sizeoptions') && <button className={classes.label} onClick={() => navigate(getUrl('/size-chart-women'))}><span> {t('product.configurable.size_guide')}</span></button>}
                            {(type === 'coloroptions') && <ColorOptions code={code} values={values} selected={selected[code]} onChange={onOptionChange} />}

                            {(type === 'sizeoptions') && <SizeOptionsBlocks code={code} values={values} selected={selected[code]} onChange={onOptionChange} />}
                        </div>
                    )
                })
            }

            {
                product?.allow_personalisation === 1 &&
                <PersonalisedOrder setPersonalisedValue={setPersonalisedValue} personalizedValue={personalisedValue}  />
            }

            <div className={classes.addtocart_container}>
                {
                    product?.is_preorder
                        ?
                        <AddToCart
                            isPreorder
                            personalisedValue={personalisedValue}
                            preorderDate={product.preorder_date}
                            product={product}
                            selectedOption={selected?.size}
                            selectedSizeLabel={selectedSizeLabel}
                            isCheckingStock={false}
                            isInStock={true}
                            onAddedToCart={ () => { setPersonalisedValue(''); } }
                        />

                        :
                        !showAlertButton
                            ?
                            <AddToCart
                                product={product}
                                giftBox
                                personalisedValue={personalisedValue}
                                selectedOption={selected?.size}
                                selectedSizeLabel={selectedSizeLabel}
                                isCheckingStock={stockChecking}
                                isInStock={cache[currentCacheKey] ? cache[currentCacheKey].quantity > 0 : false}
                                onAddedToCart={ () => { setPersonalisedValue(''); } }
                            />
                            :
                            <SubscribeStockAlert product={variant} gallery={gallery} selectedOption={selected?.size} />
                }

                {product && !giftBox && <WishlistButton product={product} className={classes.wishlist_button} size={17} />}
            </div>

        </div>
    )
}

export default ConfigurableOrder;
