import { useRequest, useStorefront } from '@kega/sps-core';
import useSystemMessage from "../useSystemMessage";

const useUpdateCustomerAddress = () => {
    const { api: { essentielCustomer } } = useStorefront();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { setSystemErrorMessage, setSystemSuccessMessage } = useSystemMessage();

    const update = async (address) => {
        try {
            setRequest();
            const result = await essentielCustomer.updateAddress(address);
            setSuccess();

            return result;

        } catch (error) {
            console.log('[useUpdateCustomerAddress] ', error);
            setError(error);

            setSystemErrorMessage(error)

            return null
        }

    };

    return {
        error,
        loading,
        update
    };
}

export default useUpdateCustomerAddress;