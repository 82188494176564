import { useCartId, useRequest, useStorefront } from '@kega/sps-core';
import { setGuestEmail as setGuestEmailState } from "../../redux/checkout/actions";
import { useDispatch } from "react-redux";
import useSystemMessage from "../useSystemMessage";

const useSetGuestEmail = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();
    const { setSystemErrorMessage, setSystemSuccessMessage } = useSystemMessage();

    const getCartId = useCartId();
    const cartId = getCartId();

    const { error, loading, success, setRequest, setSuccess, setError } = useRequest();

    const setGuestEmail = async (email) => {

        try {
            setRequest();
            const response = await checkout.setGuestEmail({ cartId: cartId, email: email });

            if (response) {
                dispatch(setGuestEmailState(response));
            }

            setSuccess();
        } catch (error) {
            console.log('[SetGuestEmail]', error);

            setSystemErrorMessage(error);

            setError(error);
        }
    };

    return {
        error,
        loading,
        success,
        setGuestEmail
    };
}

export default useSetGuestEmail;
