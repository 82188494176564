import { Outlet } from "react-router-dom";
import { useState } from "react";

import useMediaQuery from "../../hooks/useMediaQuery";

import AccountNav from './account_nav/AccountNav';
import { ImageBlock } from "../builder/components/image_block/ImageBlock";
import BuilderSection from "../builder/BuilderSection";

import { Col, Row, Container } from '../../components/grid';

import classes from "./Account.module.css";

const Account = () => {
    const { match } = useMediaQuery(`(min-width: 1025px)`);
    const [content, setContent] = useState(null);

    const renderLoadedContent = () => {
        if (content?.blocks?.length > 0) {
            if (typeof content?.blocks[0] !== 'undefined') {
                const block = content?.blocks[0]?.component?.options;

                return <ImageBlock {...block} />
            }
        }

        return null;
    }

    return (
        <>
            {
                match
                    ?
                    <>
                        <Container className={classes.builder_section}>
                            <BuilderSection model="account-top-section" renderLoading={renderLoadedContent} onContentLoaded={(data) => {
                                if (data) {
                                    setContent(data);
                                }
                            }} />
                        </Container>
                        <Container className={classes.sticky_nav}>
                            <AccountNav className={classes.nav} account />
                        </Container>
                    </>
                    :
                    null
            }
            <Container xl margins g-xs={1} g-md={3} className={classes.root}>
                <Row>
                    {
                        match
                            ?
                            <Col lg="3" />
                            :
                            null
                    }

                    <Col lg="6" xl="7">
                        <Outlet />
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Account;
