export const getCookie = (key) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift() ?? null;
    }

    return null;
}

export const setCookie = (key, value, maxAge=86400) => {
    document.cookie = `${key}=${value}; path=/; max-age=${maxAge};`;
}

export const deleteCookie = (key) => {
    document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
}
