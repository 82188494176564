import { useCartId, useConfig, useRequest, useStorefront } from '@kega/sps-core';
import { setLastOrderId, setPaymentMethodTitle } from "../../redux/checkout/actions";
import { useDispatch, useSelector } from "react-redux";

import useReCaptcha from '../useReCaptcha';
import useSystemMessage from "../useSystemMessage";

const usePlaceOrder = () => {
    const { api: { checkout } } = useStorefront();
    const dispatch = useDispatch();
    const config = useConfig();
    const { brands } = config.get('adyen');
    const { recaptchakey } = config.get('google');
    const language = config.get('language');
    const { recaptcha_place_order: placeOrderReCaptcha } = useSelector((state) => state.storeConfig);

    const getCartId = useCartId();
    const cartId = getCartId();

    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { setSystemErrorMessage, setSystemSuccessMessage } = useSystemMessage();

    const { loadToken, loading: loadingReCaptcha } = useReCaptcha({
        key: recaptchakey,
        action: 'checkout',
        language: language,
        autoLoad: false,
        reCaptchaEnabled: placeOrderReCaptcha !== null
    });

    const placeOrder = async (state, options) => {
        try {
            setRequest();

            let headers = {};

            if (placeOrderReCaptcha) {
                const recaptchaToken = await loadToken();

                headers = {
                    'X-ReCaptcha': recaptchaToken
                }
            }

            const response = await checkout.placeOrder({ cartId: cartId, stateData: state, brandConfig: brands }, options, headers);
            if (response) {
                dispatch(setPaymentMethodTitle(response?.setPaymentMethodOnCart?.cart?.payment_method_title));
                dispatch(setLastOrderId(response?.placeOrder?.order?.order_number));
            }

            setSuccess();

            return response;

        } catch (error) {
            console.log('[PlaceOrder]', error);

            setSystemErrorMessage(error);

            setError(error);
        }
    };

    return {
        error,
        loading,
        loadingReCaptcha,
        placeOrder
    };
}

export default usePlaceOrder;
