import { t } from "../../../lib/translations";

import CheckoutAgreements from "../../../modules/checkout/checkoutagreements/CheckoutAgreements";

import { Button } from "../../../components/form";

import classes from "./CheckoutButton.module.css";

const CheckoutButton = ({ className, methodSelected, sending, onButtonPress, checkboxRef, acceptedTerms, onCheckboxChange }) => {
    return (
        <div className={classes.checkout_submit} id={"submit-payment"}>
            <CheckoutAgreements checkboxRef={checkboxRef} checkboxChecked={acceptedTerms} onCheckboxChange={onCheckboxChange} />
            <Button
                className={className}
                variant={"primary"}
                onClick={() => onButtonPress()}
                disabled={!methodSelected || !acceptedTerms}
                loading={sending}>
                {t('checkout.payment.btn_send')}
            </Button>
        </div>
    )
}

export default CheckoutButton;
