import { ProductImage } from '@kega/sps-components';
import { useConfig } from '@kega/sps-core';
import { Link } from "react-router-dom";

import { t } from "../../../lib/translations";

import useStoreViews from '../../../hooks/useStoreViews';

import Price from "../../../components/price/Price";

import classes from './CheckoutItem.module.css';
import classNames from 'classnames';

const CheckoutItem = ({ className = '', id, configurable_options = null, name, sku, url_key, prices, price_range, quantity, media_gallery, att_text_perfion_googlecolour, att_text_perfion_productgroupcode, is_preorder, preorder_date,personalised_value }) => {
    const config = useConfig();

    const rowTotalIncl = prices ? prices.row_total_including_tax.value : 0;
    const rowTotalEx = prices ? prices.row_total.value : 0;
    const displayTaxBeforeAddress = config.get('displayTaxBeforeAddress');

    const { getUrl } = useStoreViews();
    const { prefix_product } = config.get('url');

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.left}>
                <div className={classes.image}>
                    <Link to={getUrl(`${prefix_product}/${url_key}`)}>
                        <ProductImage
                            src={media_gallery[0]?.url ? media_gallery[0].url : '#'}
                            alt={name} />
                    </Link>
                </div>
                <div className={classes.details}>
                    <Link className={classes.product_name} to={getUrl(`${prefix_product}/${url_key}`)}>
                        {name}
                    </Link>
                    <span className={classes.attribute_options}>
                        {att_text_perfion_googlecolour} | {configurable_options[0]?.value_label}
                    </span>

                    <span className={classes.attribute_options}>
                        {t('checkout.item.qty')} {quantity}
                    </span>

                    {
                        (is_preorder === 1 || is_preorder === 'Yes') &&
                        <div className={classes.preorder}>
                            {t('product.preorder.caption', { date: preorder_date || '?' })}
                        </div>
                    }

                    {
                        (personalised_value) &&
                        <div className={classNames(classes.personalised_order, classes.caption_text)}>
                            {t('product.personalised_order.cart.caption', { value: personalised_value })}
                            <br/>
                            {t('product.personalised_order.cart.text')}
                        </div>
                    }


                    <div className={classes.details_bottom}>
                        <div className={classes.price_wrapper}>
                            {
                                price_range
                                &&
                                <Price isCart className={classes.checkout_price} rootClassName={classes.price_wrapper} price={displayTaxBeforeAddress ? rowTotalIncl: rowTotalEx} originalPrice={price_range.minimum_price.regular_price.value * quantity} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutItem;
