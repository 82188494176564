import { Fragment, useMemo } from 'react';

import { Link } from 'react-router-dom'

import useStoreViews from "../../hooks/useStoreViews";

import classes from './Breadcrumb.module.css';

const Breadcrumb = ({ paths = [], prefix = [], sufix = [] }) => {
    const { getUrl } = useStoreViews();

    const all_paths = useMemo(() => {
        if (paths === null) { return []; }

        return [
            ...prefix,
            ...paths,
            ...sufix
        ]
    }, [paths, prefix, sufix]);

    return (
        <div className={classes.root}>
            {
                all_paths.map(({ label, path }, index) => {
                    return (
                        <Fragment key={"b" + index}>
                            {index !== 0 && <span className={classes.divider}>/</span>}
                            {
                                path
                                    ?
                                    <Link to={getUrl(path)} className={classes.link}>{label}</Link>
                                    :
                                    <span className={classes.link}>{label}</span>
                            }
                        </Fragment>
                    )
                })
            }
        </div>
    );
}

export default Breadcrumb;
