import { useDispatch } from 'react-redux';
import { useStorefront, useRequest } from '@kega/sps-core';

import useSystemMessage from "../useSystemMessage";

import { addToken, authenticated } from '../../redux/customer/actions';

const useLogin = () => {

    const { api: { customer } } = useStorefront();
    const dispatch = useDispatch();

    const { setSystemErrorMessage, setSystemSuccessMessage } = useSystemMessage();


    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    
    const login = async (params, headers = {}) => {
    
        try {
            setRequest();

            const result = await customer.login( { username: params.username, password: params.password }, headers);

            dispatch(addToken({
                token: result?.token,
                token_params: result?.params || {}
            }));
            dispatch(authenticated(true));

            setSuccess();

            return result?.token;

        } catch (error) {
            console.log('[useLogin]', error);

            setError(error);
            setSystemErrorMessage(error);
            return null
        }
       
    };

    return {
        error,
        loading,
        login
    };
}

export default useLogin;