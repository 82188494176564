import { forwardRef } from "react";
import { useConfig } from "@kega/sps-core";

import { t, translateRegion, regionNameInEnglish, translateLanguage } from "../../../lib/translations";
import { getSymbol } from "../../../lib/currency";

import useStoreViews from "../../../hooks/useStoreViews";

import Modal from "../../modal/Modal";

import classes from './SelectModal.module.css';
import classNames from "classnames";

const SelectModal = ({ closeStoreSelector, isStoreSelectorOpen  }, ref) => {
    const { storeViews, currentStoreCode, currentStoreView, checkIfKiosk } = useStoreViews();
    const config = useConfig();
    const { hideCountry } = config.get('storeSelector');

    const currentIsKiosk = checkIfKiosk(currentStoreCode);

    let storeViewsByCountry = {};

    Object.keys(storeViews).forEach(storeCode => {
        const storeView = storeViews[storeCode];

        if (storeView.country) {
            if (!storeViewsByCountry.hasOwnProperty(storeView.country)) {
                storeViewsByCountry[storeView.country] = [];
            }

            storeViewsByCountry[storeView.country][storeCode] = storeView;
        }
    });

    const languageSwitch = (e, storeCode) => {
        e.preventDefault();
        window.location.href = `/${storeCode}`;
    };

    const isUserAgentBot = () => {
        return /bot|googlebot|crawler|spider|robot|crawling|slackbot/i.test(navigator.userAgent);
    };

    if (isUserAgentBot()) { return null; }

    return (
        <Modal
            open={isStoreSelectorOpen}
            onClose={closeStoreSelector}
            title={t('store_selector.modal.title')}
            selectModal={true}
            country={regionNameInEnglish(currentStoreView.country)}
            currency={getSymbol(currentStoreView.currency)}
            modalClassName={classes.modalSelect}
            closeContainerClass={classes.closeSelect}
            className={classes.close_icon}
            description={t('store_selector.modal.description', { country: translateRegion(currentStoreView.country), currency: getSymbol(currentStoreView.currency) })}
        >
            <ul className={classes.options}>
                {Object.keys(storeViewsByCountry).map(countryCode => {
                    const countryStoreViews = storeViewsByCountry[countryCode];

                    if (hideCountry.includes(countryCode)) {
                        return false;
                    }

                    return (
                        <li key={countryCode} className={classes.row}>
                            <div className={classes.country}>
                                <img className={classes.flag} src={`/images/flags/${countryCode}.svg`} alt={regionNameInEnglish(countryCode)} />
                                <span>{regionNameInEnglish(countryCode)}</span>
                            </div>
                            <div className={classNames(classes.languages)}>
                                {Object.keys(countryStoreViews).map((storeCode) => {
                                    const storeView = countryStoreViews[storeCode];
                                    const languageClass = classNames(
                                        classes.language,
                                        ((currentStoreCode === storeCode) ? classes.current : '')
                                    );

                                    if (hideCountry.includes(storeCode)) {
                                        return false;
                                    }

                                    if (
                                        (checkIfKiosk(storeCode) && !currentIsKiosk)
                                        ||
                                        (!checkIfKiosk(storeCode) && currentIsKiosk)
                                    ) {
                                        return false;
                                    }

                                    return (
                                        <a
                                            className={languageClass}
                                            key={storeCode}
                                            onClick={(e) => languageSwitch(e, storeCode)}
                                            role="button"
                                            tabIndex="0"
                                        >
                                            {translateLanguage(storeView.language)}
                                        </a>
                                    );
                                })}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </Modal>
    );
};

export default forwardRef(SelectModal);
