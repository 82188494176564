import { Builder, BuilderBlocks } from "@builder.io/react/lite";

import Card from "../../../../components/card/Card";

import classes from './Card.module.css';

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const CardBlock = (props) => {
    const { firstblock, builderBlock } = props;
    if (firstblock?.length > 0 && firstblock?.[0]?.component?.name === "Dummy") {
        firstblock?.shift();
    }

    return (
        <Card className={classes.card}>
            <BuilderBlocks
                child
                blocks={firstblock}
                parentElementId={builderBlock && builderBlock.id}
                dataPath={`component.options.firstblock`} 
            />
        </Card>
    )
};

Builder.registerComponent(CardBlock, {
    name: "Card block",
    static: true,
    defaultStyles: {
        marginTop: "0px",
    },
    inputs: [
        {
            name: 'firstblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        }
    ],
});