const adyenGiftcardPaymentQuery = `
     query AdyenGiftcardPayment($cartId: String!, $orderNumber: String!, $cardInfo: AdyenGiftcardPaymentInput!) {
        adyenGiftcardPayment(
            cart_id: $cartId, 
            order_number: $orderNumber, 
            card_info: $cardInfo
        ) {
            isFinal
            resultCode
            action
        }
     }
`;

export default adyenGiftcardPaymentQuery;
