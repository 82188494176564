import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    isNewsletterOverlayOpen: true,
    pageCount: 1,
};

export default createReducer(initialState, {
    [actions.newsletterOverlayOpen]: (state) => {
        state.isNewsletterOverlayOpen = true;
    },
    
    [actions.newsletterOverlayClose]: (state) => {
        state.isNewsletterOverlayOpen = false;
    },

    [actions.setPageCount]: (state, action) => {
        if (typeof action.payload === 'function') {
            state.pageCount = action.payload(state.pageCount);
        } else {
            state.pageCount = action.payload;
        }
    },
});
