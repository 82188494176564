import { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";

import useFetchProductBreadcrumbs from '../../hooks/useFetchProductBreadcrumbs';

import Breadcrumb from '../breadcrumb/Breadcrumb';

import classes from './ProductBreadcrumb.module.css';

const ProductBreadcrumb = ({ productId, categories }) => {
    const { loading, fetch, error } = useFetchProductBreadcrumbs();
    const [breadCrumbs, setBreadCrumbs] = useState([]);

    useEffect(async () => {
        if (productId && categories) {
            const response = await fetch(productId, categories);
            setBreadCrumbs(response);
        }
    }, [productId, categories]);

    return (
        <div className={classes.root}>
            {
                loading
                    ?
                    <Skeleton />
                    :
                    (breadCrumbs?.category && breadCrumbs?.category?.length > 0) &&
                    <Breadcrumb
                        prefix={[{ label: 'Home', path: '/' }]}
                        sufix={[{
                            label: breadCrumbs?.product
                        }]}
                        paths={breadCrumbs?.category}
                    />
            }
        </div>
    );
}

export default ProductBreadcrumb;
